.pageContainer {
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 40px;

    max-width: 90%;
}

.title {
    font-family: 'Rubrik New', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    text-align: center;

    color: #2C2C2C;
}

.description {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    white-space: pre-wrap;

    color: #2C2C2C;
}

.image {
    position: relative;
    width: 584px;
    height: 240px;
}

.wAuto {
    width: auto;
}

@media screen and (max-width: 940px) {
    .image {
        width: 100%;
        height: auto;

        max-width: 389px;
        max-height: 160px;
    }

    .title {
        font-size: 28px;
        line-height: 36px;
    }

    .description {
        font-size: 24px;
        line-height: 32px;
    }
}

@media screen and (max-width: 460px) {
    .container {
        row-gap: 24px;
    }
}
